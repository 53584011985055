'use client'

import ApiUtils from '@/utils/ApiUtils';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useEffect } from 'react';

export default function LogRocketPlugin(props) {
  
  useEffect(() => {
    if (process.env.NODE_ENV != 'development' && ApiUtils.visitorId() && props.config.creds?.lg_id) {
      // LogRocket.init("qwdq7y/highroadx");
      LogRocket.init(props.config.creds?.lg_id)
      setupLogRocketReact(LogRocket);
      console.log(props.vid)
      LogRocket.identify(ApiUtils.visitorId(), {
      });
    }
  }, [ApiUtils.visitorId()])
  
  return <>
  </>
  
}